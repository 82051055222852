<template>
    <div class="loginRoot">
            <div class="ldiv">
                <h1>{{this.$CommunityTitle}}</h1>
                <div class="lug">
                    <div :class="isDl ? 'form' : 'form2'">
                        <el-form v-show="isDl" class="loginForm" label-position="left" label-width="80px" :model="form">
                            <el-form-item label="用户名">
                                <el-input id="input" placeholder="请输入账号" v-model="form.username" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input @keyup.enter.native="onSubmit" id="input" placeholder="请输入密码" type="password" show-password clearable v-model="form.password"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit()">登录</el-button>
                                <!--<el-button type="info" @click="isDl = false">注册</el-button>-->
                            </el-form-item>
                        </el-form>
                        <el-form v-show="!isDl" class="loginForm" label-position="left" label-width="100px" :model="form">
                            <el-form-item label="用户名">
                                <el-input id="input" placeholder="请输入账号" v-model="form.username" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input id="input" placeholder="请输入密码" type="password" show-password v-model="form.password" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="确认密码">
                                <el-input id="input" placeholder="请再次输入密码" type="password" show-password v-model="form.password2" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="姓名">
                                <el-input id="input" placeholder="请输入姓名" v-model="form.name" clearable></el-input>
                            </el-form-item>
                            <el-form-item label="联系电话">
                                <el-input id="input" placeholder="请输入联系电话" v-model="form.tel" clearable></el-input>
                            </el-form-item>
                            <el-form-item>
                                <span class="yiyou" @click="isDl = true">已有账号直接登录</span>
                                <el-button type="primary" @click="handleAdd()">注册</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
    
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            form: {
                username: "",
                password: "",
                // username: "admin",
                // password: "admin123456lj",

                password2: "",
                name: "",
                tel: "",
            },
            isDl: true,
            time: ''
        };
    },
    // async created() {
    //     let query = this.$route.query;
    //     if (query['userId']) {
    //         const res = await this.$axios({
    //             url: "/api11/api/logins",
    //             method: "post",
    //             data: { userId: query['userId'] },
    //             headers: {
    //                 "Content-Type": "application/x-www-form-urlencoded"
    //             }
    //         });
    //         if (res.data.code === 200) {
    //             localStorage.setItem("CurUser", JSON.stringify({ username: query['username'], userId: query['userId'] }));
    //             localStorage.setItem("token", res.data.token);
    //             this.$router.replace("/person");
    //         } else {
    //             // next("/login");
    //         }
    //     }
    // },
    mounted() {
        this.sendTs()
    },
    methods: {
        sendTs() {
            const {username} = this.form
            let ts = (new Date()).getTime()
            this.time = ts
            this.$axios({
                // url: this.$httpUrl +  "/api/ts",
                url: "/api11/api/ts",
                method: "post",
                data: {user: username, ts: this.time},
                headers:{
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }).then(res=>res.data).then(res => {
                if (res.code == 500) {
                    if (res.token) {
                        localStorage.setItem("CurUser", JSON.stringify({username: res.username}));
                        localStorage.setItem("token", res.token)
                        this.$router.replace("/person");
                    }
                }
            })
        },
        async onSubmit() {
            const {username, password} = this.form
            if (this.form.username && this.form.password) {
                await this.sendTs()
                this.$axios({
                    url: "/api11/api/login",
                    // url: this.$httpUrl + "/api/login",
                    method: "post",
                    data: {user: username, passwd: password, time: this.time},
                    headers:{
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }).then(res => res.data).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            message: res.msg,
                            type: "success",
                        });
                        localStorage.setItem("CurUser", JSON.stringify({username}));
                        localStorage.setItem("token", res.token)
                        this.$router.replace("/person");
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                    }
                })
            } else {
                // this.sendTs()
                this.$message({
                    message: "账号密码必填",
                    type: "error",
                });
                return false;
            }
        },
        handleAdd() {
            const {username, password, name, tel} = this.form
            if (this.form.password !== this.form.password2) {
                this.$message({
                    message: "两次输入的密码不同",
                    type: "error",
                });
            } else if (!this.form.username) {
                this.$message({
                    message: "账号必填",
                    type: "error",
                });
            } else if (!this.form.name) {
                this.$message({
                    message: "姓名必填",
                    type: "error",
                });
            } else if (!this.form.tel) {
                this.$message({
                    message: "联系电话必填",
                    type: "error",
                });
            } else {
                // 注册
                this.$axios
                    .post(this.$httpUrl + "/user/register", {username, password, name, tel})
                    .then((res) => res.data)
                    .then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: "注册成功，请前往登录！",
                            type: "success",
                        });
                        setTimeout(()=>{
                            this.isDl = true
                        }, 2000)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: "error",
                        });
                        return false;
                    }
                });
            }
        }
    },
    };
</script>

<style lang="scss">
.loginRoot {
    width: 100vw;
    height: 100%;
    position:  absolute;
    background-size: cover;
    background-image: url("../../assets/new/bg.jpg")
}
.ldiv {
    width: 500px;
    height: 100%;
    left: 50%;
    color: #03ECF1;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1 {
        color: #03ECF1;
        font-size: 28px;
    }
}
.lug {
    margin-top: 20px;
}
.form {
    background-image: url("../../assets/new/borderall.png");
    background-size: cover;
    width: 480px;
    height: 400px;
    line-height: 400px;
    display: flex;
    align-items: center;
}
.form2 {
    background-image: url("../../assets/new/borderallre.png");
    background-size: cover;
    width: 480px;
    height: 582px;
    line-height: 582px;
    display: flex;
    align-items: center;
}
.loginForm {
    width: 70%;
    left: 15%;
    position: relative;
    .el-form-item__label {
        color: #03ECF1 !important;
        text-align: justify !important;
        text-align-last: justify !important;
    }
    #input {
        background: #113981 !important;
        height: 36px;
        color: white;
        font-size: 14px;
        border-top: 1px solid rgb(60, 123, 240); /* 上边框 */
        border-bottom: 1px solid rgb(60, 123, 240); /* 下边框 */
        border-left: none; /* 左边框去掉 */
        border-right: none; /* 右边框去掉 */
    }
    .asd .el-input {
        width: 60%;
    }
    .asd .el-form-item__content {
        display: flex;
    }
    .yzm {
        height: 36px;
        width: 33%;
        margin-left: 5%;
        display: inline-block;
        border: 1px solid #03ECF1;
        cursor: pointer;
    }
    
    .el-input::before {
        content: url('../../assets/new/input_left.png'); /* 左图像的路径 */
        position: absolute;
        top: 70%; /* 将图像垂直居中 */
        // left: -12px;
        transform: translateY(-50%); 
    }
    
    .el-input::after {
        content: url('../../assets/new/input_right.png'); /* 右图像的路径 */
        position: absolute;
        top: 70%; /* 将图像垂直居中 */
        right: 0;
        transform: translateY(-50%); 
    }
    
    
    .yiyou {
        margin-right: 10px;
        font-size: 12px;
        cursor: pointer;
    }
}
  </style>