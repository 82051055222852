import axios from 'axios';
import { Message } from 'element-ui';


// 创建一个axios实例
const instance = axios.create({
  timeout: 10000, // 设置超时时间
  headers: {
    'Content-Type': 'application/json', // 设置请求头
  },
});

// 封装请求函数
export const request = async ({url, method = 'post', data = null}) => {
  try {
    let response = {}
    if (method == 'post') {
      response = await instance({
        method,
        url:`/api11${url}`,
        data,
        headers: {
          token: window.localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
    } else {
      response = await instance({
        method,
        url:`/api11${url}`,
        params: data,
        headers: {
          token: window.localStorage.getItem("token")
        }
      });
    }
    if (response.data.code != 200) {
      Message({
          message: response.data.msg,
          type: "error",
      });
      if (response.data.msg == '访问过期了') {
        setTimeout(() => {
          window.location.href = '/logins';
        }, 2000)
      }
    }
    return response.data;
  } catch (error) {
    Message({
        message: "操作失败，请稍后再试",
        type: "error",
    });
    // throw new Error(error.message);
  }
};
